var modalAdditionalData = document.getElementById('js-modalFormAdditionalData');

document.querySelectorAll('[data-modal]').forEach(element => {
    element.addEventListener('click', function (e) {
        var currentModal = document.getElementById(element.getAttribute('data-modal'));
        var textArea = currentModal.querySelector('textarea');

        if(element.getAttribute('data-modal-type')) {
            textArea.classList.remove('d-none');
            textArea.required;
        }
        else {
            textArea.classList.add('d-none');
            textArea.required = false;
        }

        var script = document.getElementById('route-modal');
        var scriptRoute = script.getAttribute('data-route');
        var newScriptRoute = scriptRoute;

        if(scriptRoute.includes('modal') && element.getAttribute('data-modal-type')) {
            newScriptRoute = scriptRoute.replace('modal', 'contact');
        }
        else {
            newScriptRoute = scriptRoute.replace('contact', 'modal');
        }
        script.setAttribute('data-route', newScriptRoute);

        if(currentModal) {
            currentModal.classList.add('show');
            loadHiddenData();
        }

    });
});

document.querySelectorAll('.own-modal-close').forEach(element => {
    element.addEventListener('click', function (e) {
        hideModal(e.target.parentElement.parentElement.parentElement);
    });
});

document.addEventListener('click', function (e) {
    if(e.target.classList.contains('own-modal')) {
        hideModal(e.target);
    }
    if(e.target.classList.contains('own-modal-wrapper')) {
        hideModal(e.target.parentElement);
    }
});

function hideModal(ele) {
    ele.classList.add('hide');
    setTimeout(function () {
        ele.classList.remove('show', 'hide');
    },500, ele);
}

function loadHiddenData() {
    cleanAdditionalData();
    setGoal();
    setType();
    setRange();
    if(document.querySelector('.calculator-content-goal .selected').getAttribute('data-goal') === 'hyp') {
        setRange('credit');
        setRange('interest');
    }
    setValue();
}


function setGoal() {
    var type = document.querySelector('.calculator-content-goal .selected').getAttribute('data-goal');
    var value;
    if(type === 'hyp') {
        value = 'Hypotéka'
    }
    else if(type === 'dch') {
        value = 'Dôchodok';
    }
    else if(type === 'det') {
        value = 'Ďalšia generácia';
    }
    else if(type === 'ine') {
        value = 'Jednorázová investícia';
    }
    else {
        value = 'Nezadané';
    }
    return renderInput('goal', value);
}

function setType() {
    var type = document.querySelector('.calculator-content-type .selected').getAttribute('data-type');
    var value;
    if(document.querySelector('.calculator-content-goal .selected').getAttribute('data-goal') !== 'ine') {
        if(type === 'kon') {
            value = 'Konzervatívny | 5%'
        }
        else if(type === 'dyn') {
            value = 'Vyvážený | 8%';
        }
        else if(type === 'riz') {
            value = 'Dynamický | 10%';
        }
        else {
            value = 'Nezadané';
        }
    }
    else {
        value = 'Jednorázová investícia | 10%';
    }

    return renderInput('type', value);
}

function setRange(el) {
    var wrappers = el ? document.querySelectorAll(`.js-${el}-slider`) : document.querySelectorAll('.js-years-slider');
    var years;
    var value;
    wrappers.forEach((element) => {
        if(element.style.display === 'block') {
            years = element.querySelector('.js-period-slider').noUiSlider.get();
        }
    });
    if(Array.isArray(years)) {
        value = Math.round(years[1] - years[0] );
    }
    else {
        value = years;
    }
    return renderInput(el ? el : 'years', value);
}

function setValue() {
    var wrappers = document.querySelectorAll('.calculator-content-value-wrapper');
    var current;
    var value;
    wrappers.forEach((element) => {
        if(element.style.display === 'block') {
            current = element.querySelector('.calculator-content-value .selected');
        }
    });
    if(current.value) {
        value = current.value;
    }
    else {
        value = current.getAttribute('data-value');
    }
    return renderInput('value', value);
}


function renderInput(ele, val) {
    var markup = `<input type="hidden" name="${ele}" value="${val}" class="js-form-hidden">`;
    modalAdditionalData.insertAdjacentHTML('beforeend', markup);
}

function cleanAdditionalData() {
    modalAdditionalData.innerText = '';
}
